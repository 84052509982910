* {
    margin: 0;
    padding: 0;
    scroll-behavior: auto;
}

body {
    font-family: Arial;
}

html {
    scroll-padding-top: 70px;
}

@media only screen and (max-width: 990px) {
    html {
        scroll-padding-top: 250px;
    }
}

/* Nav Bar */
.top-bar {
    background-image: linear-gradient(to right, #630380, #7409d8);
    /* width: 20px;
    height: 20px; */
    /* padding: 1px !important; */
}

@media only screen and (max-width: 990px) {
    .top-bar {
        display: none;
    }
}


.top-bar .topline {
    display: block;
    margin: auto !important;
    justify-content: center !important;
    text-align: center !important;

}

.top-bar.sticky,
.navbar.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: background-color 0.8s ease;
    /* Adjust the duration as needed */

}

.top-bar .fa {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

#nav-bar {
    position: sticky;
    top: 0;
    z-index: 10;
    transition: background-color 0.8s ease;
    /* Adjust the duration as needed */

}

.navbar {
    background-image: linear-gradient(to right, #630380, #7409d8);
    padding: 0 !important;

}

.navbar-brand {
    font-size: 20px;
    font-weight: 700;
    color: #fff !important;
    padding-bottom: 3px;
    margin-left: 5%;
    margin-top: 5px;
}


.navbar-nav li {
    padding: 5px 10px;
}

.navbar-nav li:hover {
    opacity: 0.7;
    border-bottom: 1px solid #fff;
}

/* .navbar-nav li.active{
    opacity: 0.7;
    border-bottom: 2px solid #fff;
} */




.navbar-nav li.active {
    opacity: 1;
}

.navbar-nav li {
    opacity: 0.7;
}

.navbar-nav li a {
    color: #fff;
    font-weight: 600;
    float: right;
    text-align: left;
}

.fa-bars {
    /* display: inline; */
    color: #fff !important;
    font-size: 30px !important;
}

.fa-close {
    /* display: none; */
    color: #fff !important;
    font-size: 30px !important;
}

.navbar-toggler {
    outline: none !important;
}


.navbar-toggler.collapsed>.close,
.navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
    display: none;
}

.navbar-toggler.collapsed>.navbar-toggler-icon,
.navbar-toggler:not(.collapsed)>.close {
    display: inline;
}




/* Banner */

#banner {
    background-image: linear-gradient(to right, #630380, #7409d8);
    color: #fff;
    padding-top: 5%;
}

.promo-title {
    font-size: 32px;
    /* font-weight: 400; */
    margin-top: 80px;
    font-weight: 600;
    padding: 0;
}

#banner a {
    border-radius: 12px;
}

.bottom-wave {
    width: 100%;
    height: 250px;

}


#banner .fa {
    margin: 10px;
    padding: 18px;
    height: 50px;
    width: 50px;

    text-align: center;
    text-decoration: none;
    border-radius: 50%;
}

/* Add a hover effect if you want */
#banner .fa:hover {
    opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
#banner .fa-facebook {
    background: #007bb5;
    color: white;
}

/* Instagram */
#banner .fa-instagram {
    background: #007bb5;
    color: white;
}

/* Twitter */
#banner .fa-twitter {
    background: #007bb5;
    color: white;
}

/* Linkedin */
#banner .fa-linkedin {
    background: #007bb5;
    color: white;
}

.section-title-line {
    border-top: 5px solid #7409d8;
    width: 140px;
    margin: auto;
}

.display-preview {
    cursor: pointer;
}

.display-preview:hover {
    opacity: 0.7;
}


#banner .ml6 {
    position: relative;
    padding: 0;
    /* font-size: 3.3em; */
}

#banner .ml6 .text-wrapper {
    position: relative;
    display: inline-block;
    /* padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.1em; */
    overflow: hidden;
}

#banner .ml6 .letter {
    display: inline-block;
    /* line-height: 1em; */
}

#banner h1 {
    font-size: 48px;
    font-weight: 700;
}

#banner p {
    font-size: 28px;

}

#banner .btn {
    font-size: 22px;
    font-weight: 400;
    width: 200px;
    height: 50px;
    padding: 2px;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-content: center;
}

/* .stats-card{

    background: #7409d8!important;
} */
.stats-main {
    font-size: 38px;
    font-weight: 700;
}

.stats-secondary {
    font-size: 24px;
    /* font-weight: 900; */
}

.card-stats {
    background: linear-gradient(to right, #630380, #7409d8);
    padding: 20px;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 220px !important;
    color: #fff;
    transition: transform 0.3s ease-in-out;
}

.card-stats:hover {
    transform: scale(1.1);
}


.card-stats img {
    height: 80px;
    width: 80px;
}


/* End Banner */

/* Services */

.service_img {
    width: 150px;
    height: 150px;
}

.youtube {
    padding-top: 10px;
    text-align: center;
    align-content: space-around;
}





.product_cover {
    padding-top: 50px;
    background-color: #e0e7eebb;
    
}

.product_cover_title {
    color: #EA4E56;
    font-size: 50px;
    font-weight: bold;
}

.product_cover_details {
    color: #585CDC;
    font-size: 25px;
    font-weight: bold;
}



.bottom-wave_services {

    width: 100%;
    height: 100px;
    background: #e0e7eebb;
}

/* End Services */


/* Features */
@media only screen and (min-width: 990px) {
    .card-body {
        height: 100px !important;
    }

    #services .card-service {
        width: 350px;
        height: 400px;
        padding: 5px;
    }
}

#services .card-service h3 {
    font-size: 18px;
    font-weight: 600;
}

#services .card-service p {
    font-size: 15px;
}

#services .card-service:hover {
    cursor: pointer;
    border: black 1px solid;
    box-shadow: gray 2px;
}

#services {
    background: #e0e7eebb;
    padding-top: 50px;
}

.service_cover {
    padding-top: 50px;
    background-color: #fff;
    
}

.service_cover_title {
    color: #EA4E56;
    font-size: 50px;
    font-weight: bold;
}

.service_cover_details {
    color: #585CDC;
    font-size: 25px;
    font-weight: bold;
}

.about_cover {
    padding-top: 50px;   
    background-color: #e0e7eebb; 
}

.about_cover_title {
    color: #EA4E56;
    font-size: 50px;
    font-weight: bold;
}

.about_cover_details {
    color: #585CDC;
    font-size: 25px;
    font-weight: bold;
}

.features_img {
    cursor: pointer;
}

.features_img:hover {
    opacity: 0.8;
}

#services .fa {
    color: #7409d8;
    font-size: 100px;
}

#services .fa:hover {
    opacity: 0.6;
}

#product .product-btn {
    align-items: center;
    text-align: center;
    /* margin-left: auto!important; */
}

.product-img {
    /* height: 100%; */
    justify-content: center !important;
    align-items: center;
    /* margin: auto!important;
    vertical-align: middle; */
}


.career_cover {
    padding-top: 50px;   
    background-color: #e0e7eebb; 
}

.career_cover_title {
    color: #EA4E56;
    font-size: 50px;
    font-weight: bold;
}

.career_cover_details {
    color: #585CDC;
    font-size: 25px;
    font-weight: bold;
}

.career-img {
    /* height: 100%; */
    justify-content: center !important;
    align-items: center;
    /* margin: auto!important;
    vertical-align: middle; */
}

#about {
    padding-top: 50px;
}

.about-img {
    /* height: 100%; */
    justify-content: center !important;
    align-items: center;
    /* margin: auto!important;
    vertical-align: middle; */
}



/* End Features*/

/* Contact */

.contact_form {
    border: 1px solid gray;
    width: 80%;
    -webkit-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);

    padding: 20px;

}

#contact form {
    margin: auto;
    transition: all 4s ease-in-out;

}

#contact .form-control {

    border: none;
    outline: none;
    border-bottom: 1px solid gray;
}

#contact .submit {
    width: 200px;
    height: 50px;
}
.contact_cover {
    padding-top: 50px;   
    background-color: #e0e7eebb; 
}

.contact_cover_title {
    color: #EA4E56;
    font-size: 50px;
    font-weight: bold;
}

.contact_cover_details {
    color: #585CDC;
    font-size: 25px;
    font-weight: bold;
}

/* End Contact */

/*Footer*/

#footer {
    color: #fff;
}

.quickLinks li {
    padding: 5px;
    list-style: none
}


#footer .fa {
    padding: 10px;
    font-size: 26px;
    width: 40px;
    height: 40px;
    text-decoration: none;
    margin: 5px 2px;
}

#footer .fa:hover {
    font-size: 30px;
}

#footer .fa:hover {
    opacity: 0.7;
}

#footer .fa-facebook {
    background: #125688;
    color: white;
}

#footer .fa-twitter {
    background: #55ACEE;
    color: white;
}

#footer .fa-google {
    background: #dd4b39;
    color: white;
}

#footer .fa-linkedin {
    background: #125688;
    color: white;
}

#footer .fa-youtube {
    background: #bb0000;
    color: white;
}

#footer .fa-instagram {
    background: #125688;
    color: white;
}



#footer {
    background-image: linear-gradient(to right, #630380, #7409d8);
}

.bottom-wave_footer {

    width: 100%;
    height: 100px;
    background-image: linear-gradient(to right, #630380, #7409d8);
}

/* End Footer*/


.tilted-card {
    transform: skewX(-15deg);
    /* Adjust the angle as needed */
    transition: background-color 0.3s, color 0.3s;

}

.tilted-card {
    background-color: #f8f9fa;
    /* Change to your desired background color */
    color: #007bff;
    /* Change to your desired text color */
    cursor: pointer;
}

/* To adjust spacing between tilted cards */
.slick-slide.tilted-card {
    margin-right: 20px;
    /* Adjust as needed */
}


/* Add the following styles to your existing CSS or in a separate file */

/* card color gradiant */
.card-service {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #630380, #7409d8);
    /* Change to your linear gradient */
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.card-service:hover .card-overlay {
    opacity: 0;
}

.card-service:hover {
    background: linear-gradient(to right, #630380, #7409d8);
    /* Change to your linear gradient */
    color: #ffffff;
    /* Set the text color to white on hover */
    cursor: pointer;
}

/* card color gradiant */
/* Add the following styles to your existing CSS or in a separate file */

#stats {
    background: #e0e7eebb;

}

.slick-prev:before,
.slick-next:before {
    color: black;

}

#technologies {
    background-color: #e0e7eebb;
    margin: auto;
    padding-top: 40px;
}



@media only screen and (min-width: 990px) {
    .card-body {
        height: 100px !important;
    }

    #ourClients .card-service {
        width: 250px;
        height: 250px;
        padding: 5px;
    }
}

#ourClients .card-service h3 {
    font-size: 18px;
    font-weight: 600;
}

#ourClients .card-service p {
    font-size: 15px;
}

#ourClients .card-service:hover {
    cursor: pointer;
    border: black 1px solid;
    box-shadow: gray 2px;
}

#ourClients {
    background: #fff;

}

#ourClients .ourClients-cards {
    background: #fff;
    width: 80%;
}




@media only screen and (min-width: 990px) {
    .card-body {
        height: 100px !important;
    }

    #testimonials .card-service {
        width: 350px;
        height: 400px;
        padding: 5px;
    }
}

#testimonials .card-service h3 {
    font-size: 18px;
    font-weight: 600;
}

#testimonials .card-service p {
    font-size: 15px;
}

#testimonials .card-service:hover {
    cursor: pointer;
    border: black 1px solid;
    box-shadow: gray 2px;
}

#testimonials {
    background: #e0e7eebb;
    padding-top: 50px;
}

.vision_title {
    color: #7409d8;
}


.custom-nav-item {
    color: black !important;
}

.custom-nav-item :hover {
    background-color: #fff !important;
}

.career_title {
    color: #630380;
}

.about_title {
    color: #630380;

}

.carerr_mail {
    color: #630380;

}